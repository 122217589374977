import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/webMainPage'
	},
	{
		path: '/webMainPage',
		component: () => import('@/views/buyper/webMainPage.vue')
	},
	{
		path: '/login',
		component: () => import('@/views/buyper/login.vue')
	},
	{
		path: '/updatePassWord',
		component: () => import('@/views/buyper/updatePassWord.vue')
	},
	{
		path: '/shopHall',
		component: () => import('@/views/buyper/shopHall.vue')
	},
	{
		path: '/orderSend',
		component: () => import('@/views/buyper/orderSend.vue')
	},
	{
		path: '/edi',
		component: () => import('@/views/buyper/edi.vue')
	},
	{
		path: '/contactUs',
		component: () => import('@/views/buyper/contactUs.vue')
	}
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/buyper'),
  routes
})

export default router
