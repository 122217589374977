import axios from 'axios'
axios.defaults.timeout = 60000

// let httpUrl = 'http://localhost:8103'
let httpUrl = 'http://dianyu.dianyu001.com'

export function findHttpUrl(){
  return httpUrl;
}

axios.interceptors.request.use(config => {
  config.headers['x-requested-width'] = 'XMLHttpRequest';
  config.headers['Authorization'] = window.localStorage.getItem('loginToken');
  config.headers['Content-Type'] = 'application/json';
   //发布生产不需要打包这个
  config.headers['version'] = 'abc';
  return config;
},
error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error)
  }
);

/**
 * 封装request方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function request (url, method, data = {}, headers = {}, responseType) {
  return new Promise((resolve, reject) => {
    let token = window.localStorage.getItem('loginToken')
    if (token) {
      headers.token = token
    }
    axios
      .request({
        url: formatUrl(url),
        method: method,
        data: data,
        headers: headers,
        responseType: responseType ? `${responseType}` : 'json'
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log('err', err)
        reject(err)
      });
  });
}

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function get (url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axios.get(httpUrl + url, {
      params: params,
      headers: headers
    }).then(response => {
      resolve(response.data)
    }).catch(err => {
      //alert(err.response.data.resultCode)
      if(401 === err.response.status) {
        axios.get(httpUrl + '/auth/login/refreshLoginToken?refreshToken=' + window.localStorage.getItem('refreshToken'), {},{}).then(resp => {
          if(resp.data.success){
            window.localStorage.setItem('loginToken', resp.data.result.loginToken);
            window.localStorage.setItem('refreshToken', resp.data.result.refreshToken);
            axios.get(httpUrl + url, {
                  params: params,
                  headers: headers
                }).then(response => {
                  resolve(response.data)
                }).catch(err => {
                  reject(err);
                })
          } else {
            this.$router.push('/login');
          }
        }).catch(erro => {
          // this.$router.push('/login');
        });
      }else{
        reject(err);
      }
    });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post (url, data = {}, headers = {}, responseType) {
  return new Promise((resolve, reject) => {
    axios.post(httpUrl + url, data, {
      headers
    }, responseType).then(
      response => {
        resolve(response.data)
      },
      err => {
        if(401 === err.response.status) {
          axios.get(httpUrl + '/auth/login/refreshLoginToken?refreshToken=' + window.localStorage.getItem('refreshToken'), {},{}).then(resp => {
            if(resp.data.success){
              window.localStorage.setItem('loginToken', resp.data.result.loginToken);
              window.localStorage.setItem('refreshToken', resp.data.result.refreshToken);
              axios.post(httpUrl + url, data, {
                headers
              }, responseType).then(response => {
                    resolve(response.data)
                  }).catch(err => {
                    reject(err);
                  })
            } else {
              this.$router.push('/login');
            }
          }).catch(erro => {
            // this.$router.push('/login');
          });
        }else{
          reject(err);
        }
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(formatUrl(url), data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(formatUrl(url), data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    );
  });
}

/**
 * 封装delete方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function del (url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axios
      .delete(formatUrl(url), {
        params: params,
        headers: headers
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  });
}

export function upload (url, data, params = {}, headers = {}, responseType) {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('uploadfile', data.file)
    axios.post(httpUrl + url, formData, {
      headers,
      params
    }, responseType).then(
      response => {
        resolve(response.data)
      },
      err => {
        if(401 === err.response.status) {
          axios.get(httpUrl + '/auth/login/refreshLoginToken?refreshToken=' + window.localStorage.getItem('refreshToken'), {},{}).then(resp => {
            if(resp.data.success){
              window.localStorage.setItem('loginToken', resp.data.result.loginToken);
              window.localStorage.setItem('refreshToken', resp.data.result.refreshToken);

              axios.post(httpUrl + url, formData, {
                headers
              }, responseType).then(
                response => {
                  resolve(response.data)
                },
                err => {
                  reject(err);
               })

            } else {
              this.$router.push('/login');
            }
          }).catch(erro => {
            // this.$router.push('/login');
          });
        }else{
          reject(err);
        }
      })
  });
}

export function downloadget(fileType, url, data = {}, headers = {}) {
  axios.get(httpUrl + url, {
    params: data,
    responseType: "blob"
  }).then(res => {
        var data = res.data;
        var headers = res.headers;
        // var contentDisposition = headers["content-disposition"];
        var contentType = headers["content-type"];
        var fileName = new Date().getTime()+"."+fileType;
        var blob = new Blob([data], { type: contentType });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            let url = (window.URL || window.webkitURL).createObjectURL(blob);
            let a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.setAttribute("download", fileName);
            document.body.appendChild(a);
            a.click()
            document.body.removeChild(a); // 下载完成移除元素
            window.URL.revokeObjectURL(url); // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
        }
    }).catch(e => {
         if(401 === err.response.status) {
           axios.get(httpUrl + '/auth/login/refreshLoginToken?refreshToken=' + window.localStorage.getItem('refreshToken'), {},{}).then(resp => {
             if(resp.data.success){
               window.localStorage.setItem('loginToken', resp.data.result.loginToken);
               window.localStorage.setItem('refreshToken', resp.data.result.refreshToken);

               axios.get(httpUrl + url, {
                 params: data,
                 responseType: "blob"
               }).then(res => {
                     var data = res.data;
                     var headers = res.headers;
                     var contentType = headers["content-type"];
                     var fileName = new Date().getTime()+"."+fileType;
                     var blob = new Blob([data], { type: contentType });

                     if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
                         window.navigator.msSaveOrOpenBlob(blob, fileName);
                     } else {
                         let url = (window.URL || window.webkitURL).createObjectURL(blob);
                         let a = document.createElement("a");
                         a.style.display = "none";
                         a.href = url;
                         a.setAttribute("download", fileName);
                         document.body.appendChild(a);
                         a.click()
                         document.body.removeChild(a); // 下载完成移除元素
                         window.URL.revokeObjectURL(url); // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
                     }
                 }).catch(e => {
                      reject(err);
                 });

             } else {
               this.$router.push('/login');
             }
           }).catch(erro => {
             // this.$router.push('/login');
           });
         }else{
           reject(err);
         }
    });
}

export function downloadgetFileName(fileName, url, data = {}, headers = {}) {
  axios.get(httpUrl + url, {
    params: data,
    responseType: "blob"
  }).then(res => {
        var data = res.data;
        var headers = res.headers;
        // var contentDisposition = headers["content-disposition"];
        var contentType = headers["content-type"];
        var blob = new Blob([data], { type: contentType });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            let url = (window.URL || window.webkitURL).createObjectURL(blob);
            let a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.setAttribute("download", fileName);
            document.body.appendChild(a);
            a.click()
            document.body.removeChild(a); // 下载完成移除元素
            window.URL.revokeObjectURL(url); // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
        }
    }).catch(e => {
         if(401 === err.response.status) {
           axios.get(httpUrl + '/auth/login/refreshLoginToken?refreshToken=' + window.localStorage.getItem('refreshToken'), {},{}).then(resp => {
             if(resp.data.success){
               window.localStorage.setItem('loginToken', resp.data.result.loginToken);
               window.localStorage.setItem('refreshToken', resp.data.result.refreshToken);

               axios.get(httpUrl + url, {
                 params: data,
                 responseType: "blob"
               }).then(res => {
                     var data = res.data;
                     var headers = res.headers;
                     var contentType = headers["content-type"];
                     var fileName = new Date().getTime()+"."+fileType;
                     var blob = new Blob([data], { type: contentType });

                     if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
                         window.navigator.msSaveOrOpenBlob(blob, fileName);
                     } else {
                         let url = (window.URL || window.webkitURL).createObjectURL(blob);
                         let a = document.createElement("a");
                         a.style.display = "none";
                         a.href = url;
                         a.setAttribute("download", fileName);
                         document.body.appendChild(a);
                         a.click()
                         document.body.removeChild(a); // 下载完成移除元素
                         window.URL.revokeObjectURL(url); // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
                     }
                 }).catch(e => {
                      reject(err);
                 });

             } else {
               this.$router.push('/login');
             }
           }).catch(erro => {
             // this.$router.push('/login');
           });
         }else{
           reject(err);
         }
    });
}

export function downloadpost(fileType, url, data = {}, headers = {}, responseType) {
  var fileName = new Date().getTime()+"."+fileType;
  downloadFileNamepost(fileName, url, data, headers, responseType);
}

export function downloadFileNamepost(fileName, url, data = {}, headers = {}, responseType) {
  axios.post(httpUrl + url, data,  {
      responseType: "blob"
    }).then(res => {
        var data = res.data;
        var headers = res.headers;
        var contentType = headers["content-type"];
        var blob = new Blob([data], { type: contentType });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            let url = (window.URL || window.webkitURL).createObjectURL(blob);
            let a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.setAttribute("download", fileName);
            document.body.appendChild(a);
            a.click()
            document.body.removeChild(a); // 下载完成移除元素
            window.URL.revokeObjectURL(url); // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
        }
    }).catch(e => {
         if(401 === err.response.status) {
           axios.get(httpUrl + '/auth/login/refreshLoginToken?refreshToken=' + window.localStorage.getItem('refreshToken'), {},{}).then(resp => {
             if(resp.data.success){
               window.localStorage.setItem('loginToken', resp.data.result.loginToken);
               window.localStorage.setItem('refreshToken', resp.data.result.refreshToken);

               axios.post(httpUrl + url, data,  {
                   responseType: "blob"
                 }).then(res => {
                     var data = res.data;
                     var headers = res.headers;
                     var contentType = headers["content-type"];
                     var fileName = new Date().getTime()+"."+fileType;
                     var blob = new Blob([data], { type: contentType });

                     if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
                         window.navigator.msSaveOrOpenBlob(blob, fileName);
                     } else {
                         let url = (window.URL || window.webkitURL).createObjectURL(blob);
                         let a = document.createElement("a");
                         a.style.display = "none";
                         a.href = url;
                         a.setAttribute("download", fileName);
                         document.body.appendChild(a);
                         a.click()
                         document.body.removeChild(a); // 下载完成移除元素
                         window.URL.revokeObjectURL(url); // 只要映射存在，Blob就不能进行垃圾回收，因此一旦不再需要引用，就必须小心撤销URL，释放掉blob对象。
                     }
                 }).catch(e => {
                      reject(err);
                 });

             } else {
               this.$router.push('/login');
             }
           }).catch(erro => {
             // this.$router.push('/login');
           });
         }else{
           reject(err);
         }
    });
}

function formatUrl (url) {
  if (url.indexO('?') > 0) {
    return `${url}&_t=${Date.now()}`
  } else {
    return `${url}?_t=${Date.now()}`
  }
}
/**
 * 将base64的数据转化为Blob数据
 * @param {*} base64Str
 * @returns
 */
function dataURLtoBlob(base64Str){
    let bstr = atob(base64Str),
    n = bstr.length,
    u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
                  // 下载的是excel格式的文件
    return new Blob([u8arr], { type: "application/vnd.ms-excel" });
}
/**
 * 将byte文件下载的本地
 * @param {byte文件数据} fileByte
 * @param {文件名称} fileName
 */
export function downloadByByte(fileByte, fileName) {
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = URL.createObjectURL(dataURLtoBlob(fileByte))
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
}
